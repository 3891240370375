/**
 * <PRE>
 * System Name : 사랑온 NEXT 시스템(TSS2-MSP)
 * Business Name : 청약
 * Class Name : PSMixinTabBoxPageScroll..js
 * Description : 메인페이지에 tab으로 분리되어 있는 각 탭의 스크롤 처리 mixin
 * </PRE>
 */
export default {

  data () {
    return {
      /*------- 스크롤 처리 정의 ------ */
      isMountedMixin         : false,   // mounted 여부 mounted 함수에서 true 처리 update 함수에서 체크함
      isTabChange            : false,
      pageScrollElementMixin : null,  // 메인페이지의 스크롤 영역 Element 객체      
      tabBoxMixin            : null,              // 메인페이지의 TabBox
      curntTabIdxMixin       : 0,  
      oldTabIdxMixin         : 0,  
      moTabContentListMixin  : null          // TabBox 안의 TabConent Array
      /*------- / 스크롤 처리 정의 ------ */
    }
  },
  
  created () {
  },
  
  mounted () {
    //this.fn_SetTabBoxPageScrollMixin() // 스클롤 처리 셋팅 
    this.isMountedMixin = true
  }, 

  beforeUpdate () {
    if(! this.isMountedMixin) return

    console.log('PSMixinTabPageScroll beforeUpdate !!!!! oldTabIdxMixin='+ this.oldTabIdxMixin +' /  curntTabIdxMixin='+this.curntTabIdxMixin)

    if(this.isTabChange) {
      this.isTabChange = false
      this.fn_TabChangeScrollPosition()
    }
    
  },

  destroyed () {
    this.fn_DestroyedPageScroll()
  },  

  methods : {
    /******************************************************************************
    * Function명  : fn_SetTabBoxPageScrollMixin
    * 설명        : Main Page Scroll 셋팅 
    *              mounted 함수에서 호출
    ******************************************************************************/
     fn_SetTabBoxPageScrollMixin (refPageContainer,refTabBox) {
      
      let pageScrollId = null
      if(refPageContainer){
        pageScrollId = refPageContainer.getScrollElementId()
        this.pageScrollElement = document.getElementById(pageScrollId);
      }
      
      if(this.pageScrollElement) {
        this.pageScrollElement.removeEventListener("scroll",this.fn_OnPageScrollEvent)
        this.pageScrollElement.addEventListener("scroll",this.fn_OnPageScrollEvent,false)
      }

      if(refTabBox && refTabBox.$options && refTabBox.$options._renderChildren){
        this.tabBoxMixin = refTabBox
        this.moTabContentListMixin = this.tabBoxMixin.$options._renderChildren
        this.curntTabIdxMixin = this.tabBoxMixin.defaultIdx
        this.oldTabIdxMixin   =  this.curntTabIdxMixin

        let cnt = (this.moTabContentListMixin)? this.moTabContentListMixin.length : 0

        //moTabConent 속성에 scrollPositionMixin 추가 및 초기화 처리
        for(let i = 0 ; i < cnt ; ++i){
          this.moTabContentListMixin[i].scrollPositionMixin = 0
        }//for

        
        this.tabBoxMixin.$off('tab-change',this.fn_OnTabBoxMixinChangeEvent)
        this.tabBoxMixin.$on('tab-change',this.fn_OnTabBoxMixinChangeEvent)
      }
    },

    /******************************************************************************
    * Function명  : fn_OnTabBoxMixinChangeEvent
    * 설명        : Main Page TabBox tab change 핸들러 
    *              현재 활성중인 탭 인덱스 기억처리
    ******************************************************************************/
    fn_OnTabBoxMixinChangeEvent (tabIdx){
      this.oldTabIdxMixin = this.curntTabIdxMixin
      this.curntTabIdxMixin = tabIdx
      this.isTabChange = (this.oldTabIdxMixin == this.curntTabIdxMixin)? false : true
      
      console.log('PSMixinTabPageScroll fn_OnTabBoxMixinChangeEvent !!!!! tabIdx='+this.curntTabIdxMixin)
    },

    /******************************************************************************
    * Function명  : fn_DestroyedPageScroll
    * 설명        : Main Page Scroll 셋팅 해제 처리
    *              destroyed 함수에서 호출
    ******************************************************************************/
    fn_DestroyedPageScroll () {
      if(this.pageScrollElement) this.pageScrollElement.removeEventListener("scroll",this.fn_OnPageScrollEvent)
      if(this.tabBoxMixin) this.tabBoxMixin.$off('tab-change',this.fn_OnTabBoxMixinChangeEvent)
    },

    /******************************************************************************
    * Function명  : fn_OnPageScrollEvent
    * 설명        : 스크롤 이벤트 발생시 해당 Tab의 스크롤 위치 기억 처리
    *              fn_SetPageScroll 에서 addEventListener 처리
    ******************************************************************************/
    fn_OnPageScrollEvent(e){
      let target = e.target;
      //console.log('fn_OnPageScrollEvent !!!')

      if(! target) return
      let scrollTop = target.scrollTop 
      let scrollHeight = e.target.scrollHeight
      let clientHeight = e.target.clientHeight       
      let scrollPosition =  scrollTop
      //console.log('scrollTop='+scrollTop + " / scrollPosition ="+ scrollPosition +"/ scrollHeight="+scrollHeight + " / clientHeight="+clientHeight)

      if(! (this.moTabContentListMixin && this.moTabContentListMixin.length > 0)) return
      
      let targetTabContent = this.moTabContentListMixin[this.curntTabIdxMixin-1]
      targetTabContent.scrollPositionMixin = scrollPosition
    },

    /******************************************************************************
    * Function명  : fn_TabChangeScrollPosition
    * 설명        : tab 변경 전 beforeUpdate() 함수에서 해당 tab의 스크롤 위치 셋팅 처리
    *              
    ******************************************************************************/
    fn_TabChangeScrollPosition () {
      
      if(! (this.pageScrollElement && 
            this.moTabContentListMixin && 
            this.moTabContentListMixin.length > 0) ) return
      
      let targetTabContent = this.moTabContentListMixin[this.curntTabIdxMixin-1]
      this.pageScrollElement.scrollTop = targetTabContent.scrollPositionMixin
      
    }, 

  },

}